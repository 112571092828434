
import { useHistory } from 'react-router-dom';
import Login from './Login';
function Logout(){
const history = useHistory();
window.localStorage.clear();
localStorage.setItem('authStatus', 0);
history.push('/login');
 return(
   <>
    //<Login />
   </>
 );
}

export default Logout;
