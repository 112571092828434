import "./Faq.css";

const Faq = () => {
  return (
    <div className="faq-wraper">
      <div className="faq-content">
        <div className="faq-header">
          <h2>FAQs</h2>
          <span className="updated">Last Updated On</span>
          <span className="date">07/08/2021 : 06:30PM</span>
        </div>
        <div className="faq-content-description">
          <div className="faq-main-content">
            <div className="content-header">
              <h4>
                Added On <span>07/08/2021; 06:30PM</span>
              </h4>
            </div>
            <div className="faq-content-main">
              <h3 className="questions">
                Q. How does Anonymity relate to Privacy?
              </h3>
              <p className="answer">
                By maintaining anonymity we make sure that the identity of the
                users are kept private which would ensure that the users can
                freely express their thoughts and views without any
                hesitation.By maintaining anonymity we make sure that the
                identity of the users are kept private which would ensure that
                the users can freely express their thoughts and views without
                any hesitation.By maintaining anonymity we make sure that the
                identity of the users are kept private which would ensure that
                the users can freely express their thoughts and views without
                any hesitation.By maintaining anonymity we make sure that the
                identity of the users are kept private which would ensure that
                the users can freely express their thoughts and views without
                any hesitation.
              </p>
            </div>
          </div>
          <div className="faq-main-content">
            <div className="content-header">
              <h4>
                Added On <span>07/08/2021; 06:30PM</span>
              </h4>
            </div>
            <div className="faq-content-main">
              <h3 className="questions">
                Q. How does Anonymity relate to Privacy?
              </h3>
              <p className="answer">
                By maintaining anonymity we make sure that the identity of the
                users are kept private which would ensure that the users can
                freely express their thoughts and views without any
                hesitation.By maintaining anonymity we make sure that the
                identity of the users are kept private which would ensure that
                the users can freely express their thoughts and views without
                any hesitation.By maintaining anonymity we make sure that the
                identity of the users are kept private which would ensure that
                the users can freely express their thoughts and views without
                any hesitation.By maintaining anonymity we make sure that the
                identity of the users are kept private which would ensure that
                the users can freely express their thoughts and views without
                any hesitation.
              </p>
            </div>
          </div>
          <div className="faq-main-content">
            <div className="content-header">
              <h4>
                Added On <span>07/08/2021; 06:30PM</span>
              </h4>
            </div>
            <div className="faq-content-main">
              <h3 className="questions">
                Q. How does Anonymity relate to Privacy?
              </h3>
              <p className="answer">
                By maintaining anonymity we make sure that the identity of the
                users are kept private which would ensure that the users can
                freely express their thoughts and views without any
                hesitation.By maintaining anonymity we make sure that the
                identity of the users are kept private which would ensure that
                the users can freely express their thoughts and views without
                any hesitation.By maintaining anonymity we make sure that the
                identity of the users are kept private which would ensure that
                the users can freely express their thoughts and views without
                any hesitation.By maintaining anonymity we make sure that the
                identity of the users are kept private which would ensure that
                the users can freely express their thoughts and views without
                any hesitation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
