import React, { useState } from "react";
import AccountLeftSection from "../../components/login/account-left-section/account-left";
import "../../assets/css/login.css";
import TextField from "@mui/material/TextField";
import Arrow from "../../assets/images/login/back.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import axiosConfig from "../../config/axiosConfig";

function Forgotpassword() {
  const states = {
    input: {},
    errors: {},
  };
  const [state, setState] = useState(states);
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validater(e)) {
      const BASE_URL = process.env.REACT_APP_BASE_URL;
      let email = state.input.email;
      setLoader(true);
      axiosConfig
        .post("/admin/forgot-password", { email: email })
        .then((response) => {
          setLoader(false);
          setState((pre) => ({ ...pre, errors: {} }));
          setMsg(response.data.message);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChange = (e) => {
    let input = state.input;
    input[e.target.name] = e.target.value;
    setState((pre) => ({ ...pre, input }));
  };

  const validater = (e) => {
    let isValid = true;
    let errors = {};
    errors["email"] = "";
    let input = state.input;
    if (input.email == undefined || input.email == "") {
      isValid = false;
      setMsg("");
      errors["email"] = "Please enter your email address.";
      setState((pre) => ({ ...pre, errors: errors }));
    }
    return isValid;
  };
  return (
    <div className="accountWrapper">
      <AccountLeftSection />
      <div className="right-section">
        <div className="right-inner">
          <h1 className="page-title bottom-spacing">Forgot Your Password</h1>
          <div className="form-wrapper top-spacing">
            <form onSubmit={handleSubmit}>
              <div className="input-row">
                <label>*Email Address</label>
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  onChange={(e) => handleChange(e)}
                  value={states.input.email}
                />
                <span className="error-msg">{state.errors.email}</span>
                {msg ? <span className="success-msg">{msg}</span> : ''}
              </div>

              <div className="btn-wrap">
                <Button variant="contained" type="submit" disabled={loader}>
                  {loader && <CircularProgress size={24} />}
                  Forgot password
                </Button>
                <div className="back-login-btn">
                  <Button variant="contained">
                    <Link to="/login"> Back to Login</Link>
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <div className="right-arrow-section">
            <Link to="">
              <img src={Arrow} alt="Icon" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgotpassword;
