import { useState,useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ColumnSort from "../../../components/columnsort/Columnsort";
import "./NotificationList.css";
import axiosPrivateApi from "../../../config/axiosPrivate";
import { getStatusText, getDateOrAge,plateformType } from "../../../utils/Profile";
import Loader from "../../../components/loader/loader";
import { Link, useLocation } from "react-router-dom";
import SuccessfullPopup from "../../../components/notification/successfull-popup/SuccessfullPopup";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";

// Notification List Component
const NotificationList = (props) => {

  const [openSuccess, setSuccessOpen] = useState(false);
  const { data,...other } = props;

  const [list,setList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [loader, setLoader] = useState(false);

  const [notificationId, setNotificationId] = useState("");

  const [sorting, setSorting] = useState(false);

  const handleSuccessClickOpen = () => {
    setSuccessOpen(true);
  };

  const dispatch = useDispatch();

  var filterInput = props.data.input;
  var filterParam = {
    limit: filterInput.limit,
    //search: filterInput.search,
    //lastEvaulatedKey:'',
    //ageGroup:filterInput.ageGroup,
    //status:filterInput.status,
    //  lastEvaulatedTime:''
  };
  if (filterInput.fromDate) {
      filterParam.fromDate = filterInput.fromDate
  }
  //if (filterInput.status != '' ) {
      filterParam.status = filterInput.status
  //}
  if (filterInput.toDate) {
      filterParam.toDate = filterInput.toDate
  }
  if (filterInput.platform) {
      filterParam.platform = filterInput.platform
  }
  if (filterInput.search) {
      filterParam.search = filterInput.search
  }

  const handleSorting = (data)=>{
    setSorting(data);
  }


  //console.log("props.data.input",filterParam);
  useEffect(async () => {
    if (props.data.input.isSubmited) {
      setProcessing(true);
      //delete props.data.input.isSubmited;
      if (props.params.isFirstTime) {
          filterParam['lastEvaulatedKey'] = props.params.lastEvaulatedKey;
          filterParam['lastEvaulatedTime'] = props.params.lastEvaulatedTime;
      }
      if (sorting) {
          filterParam['sort'] = 1;
      }else{
          filterParam['sort'] = -1
      }
      filterParam['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

      await axiosPrivateApi
        .get("/admin/notification",{params: filterParam } )
        .then((res) => {
          setProcessing(false);
          res.data.data.meta.isFirstTime = -1;
          res.data.data.meta.lastPage = (res.data.data.meta.lastEvaulatedTime == undefined ?  true : false);
          dispatch({ type:"NEXT_NOTIFICATION_PAGINATION",payload:res.data.data.meta  });
          setList(res.data.data.notifications);
        })
        .catch((err) => {
          setProcessing(false);
          console.log(err);
        });
      }
  }, [props,openSuccess,sorting]);

const resendNotification = (e,timestamp)=>{
  e.preventDefault();
  setNotificationId(timestamp);
  setLoader(true);
  axiosPrivateApi
    .put("/admin/notification/resend",{createdDate:timestamp} )
    .then((res) => {
        setLoader(false);
        handleSuccessClickOpen();
    })
    .catch((err) => {
        setLoader(false);
        console.log(err);
    });

}


  var srNo = 1;
  return (
    <div className="Notification-main-wrapper">
      <div className="table-wrapper notification-list">
        {processing && <Loader />}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>
                  <div className="sort-outer">
                    <span>Title</span>
                    {/*}<ColumnSort  />*/}
                  </div>
                </TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Platform Type</TableCell>
                <TableCell>
                  <div className="sort-outer">
                    <span>Date Created</span>
                    <ColumnSort sortingCallback={handleSorting} />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="sort-outer">
                    <span>Sent on</span>
                    {/*}<ColumnSort  />*/}
                  </div>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => (
                <TableRow key={srNo}>
                  <TableCell>{srNo++}</TableCell>
                  <TableCell>{row.Title}</TableCell>
                  <TableCell>{row.Description}</TableCell>
                  <TableCell>{plateformType(row.Platform)}</TableCell>
                  <TableCell>{getDateOrAge(row.Date)}</TableCell>
                  <TableCell>{row.SentOn ? getDateOrAge(row.SentOn) :'N/A'}</TableCell>
                  <TableCell className="status-send">{ row.IsDraft ? <Link to='#' onClick={(e)=>resendNotification(e,row.Date)}>Resend { notificationId == row.Date &&  <CircularProgress size={16} />} </Link> : 'Sent' }</TableCell>
                </TableRow>
              ))}

              {list.length == 0 && (
                <TableRow >
                  <TableCell colSpan="7">No Notification found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="successfull-alert">
          <SuccessfullPopup open={openSuccess} setOpen={setSuccessOpen} />
        </div>
      </div>
    </div>
  );
};

export default NotificationList;
