import { combineReducers } from 'redux';
import sideBarReducer from '../pages/common/reducer';
import profileReducer from "../pages/profile/reducer";
import paginationReducer from "../pages/user-management/reducer";
import notificationReducer from "../pages/notification-management/reducer";
//import regisReducer from '../pages/registration/reducer';

const rootReducer = combineReducers({
    sideBarReducer,
    profileReducer,
    paginationReducer,
    notificationReducer
});

export default rootReducer;
