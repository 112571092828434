import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../assets/images/popup/img-10.png";
import CircularProgress from '@mui/material/CircularProgress';
import axiosPrivateApi from "../../config/axiosPrivate";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Deletepopup = (props) => {
  const { open, setOpen,...other } = props;
  const handleClose = () => {
    setOpen(false);
  };

const [loader, setLoader] = React.useState(false);

const changeStatus = (e)=>{
  setLoader(true);
  axiosPrivateApi
    .put("/admin/user/status", { userId:props.profileData.Id,status:3,time:props.profileData.AccountActivationDate })
    .then((response) => {
        setLoader(false);
        props.parentCallback(props.profileData.AccountActivationDate);
        setOpen(false);
    })
    .catch((err) => {
      setLoader(false);
      console.log(err);
    });
}


  return (
    <div className="popup-wrap">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Delete Confirmation
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <div className="popup-body">
            <div className="popup-img">
              <figure>
                <img src={Alert} alt="Image" />
              </figure>
            </div>
            <div className="popup-content">
              <h2 className="popup-sub-title">Delete User?</h2>
              <p className="popup-description">
                Are you sure you want to delete this user?
              </p>
            </div>
          </div>
        </DialogContent>
        <div className="popup-footer">
          <div className="popup-footer-inner">
            <div className="btn-col">
              <Button variant="contained" onClick={changeStatus} color="primary">
                { loader && (<CircularProgress size={24}/>)}
                Yes
              </Button>
            </div>
            <div className="btn-col">
              <div className="no-btn">
                <Button variant="outlined" color="error" onClick={handleClose}>
                  No
                </Button>
              </div>
            </div>
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
};

export default Deletepopup;
