import axios from 'axios';
import { useHistory } from 'react-router-dom';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const instanceNew = axios.create({
    baseURL: BASE_URL,
    //headers:{
      //Authorization:useraccessToken
    //}
});
let remember_me = window.localStorage.getItem('remember_me');
instanceNew.interceptors.request.use( config=>{
  let useraccessToken = window.localStorage.getItem('idToken');
  config.headers = {
    ...config.headers,Authorization:useraccessToken
  }
  return config;
},err=>Promise.reject(err));


instanceNew.interceptors.response.use( (res)=>{
         return res;
        },
        async (err)=>{
          var statusCode = [405];
          const originalConfig = err.config;
            if (remember_me) {

              if (statusCode.includes(err.response.status) || err.response.data.message ) {
                try {
                    let refreshToken = window.localStorage.getItem('refreshToken');
                    let userId = window.localStorage.getItem('userId');
                    const response = axios.post(BASE_URL+"/admin/renew-token", {
                      refreshToken: refreshToken,
                      userId: userId,
                    },{  headers:{
                        'Content-Type':'application/json'
                    }}).then((response) => {
                      console.log(response.data.statusCode);
                      let userInfo = response.data.data;
                      localStorage.setItem("accessToken", userInfo.accessToken);
                      localStorage.setItem("refreshToken", userInfo.refreshToken);
                      localStorage.setItem("idToken", userInfo.idToken);
                      window.location.href = '/dashboard';
                    }).catch((err) => {
                      if (err.response.data.statusCode == 400 || err.response.data.statusCode == 401) {
                        window.localStorage.clear();
                        localStorage.setItem('authStatus', 0);
                        window.location.href = '/login';
                      }
                      console.log("Refresh Token API Error =>",err.response.data.statusCode);
                      });
                    //return instanceNew(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
              }
          }else{
              if (statusCode.includes(err.response.status)) {
                  window.localStorage.clear();
                  localStorage.setItem('authStatus', 0);
                  window.location.href = '/login';
              }
          }
       return Promise.reject(err);
    }
);

export default instanceNew;
