import React from "react";
import "../../assets/css/layout.css";
import Header from "./Header";
import Navbar from "./Navbar";
import { connect } from "react-redux";


//const Layouts = ({ props}) =>
function Layouts(props){
  return (
    <React.Fragment>
      <div className="pageWrapper">
        <Header {...props} />
        <div className="main-wrapper">
          <Navbar />
          <div className={"main-section"+ (props.status ? " main-section-width" : "")}>
            <div className="main-section-inner">{props.children}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};


function mapStateToProps(state) {
  return {status:state.sideBarReducer.status};
}

export default connect(mapStateToProps)(Layouts);
