import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
const PrivacyPolicy = () => {
  const [policy, setPrivacyPolicy] = useState("");

  useEffect(() => {
    const URL = process.env.REACT_APP_BASE_URL;
    //let BASE_URL = URL.replace("admin/", "");
    axios
      .get(URL + "/privacy-policy")
      .then((response) => {
        setPrivacyPolicy(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {!policy && (
        <div
          className="content-loader"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress size={100} />
        </div>
      )}
      <div
        className="content-description-terms"
        dangerouslySetInnerHTML={{ __html: policy }}
      ></div>
    </>
  );
};

export default PrivacyPolicy;
