import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
//import RootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

import RootReducer from './rootReducer';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,whitelist:[]
}

const persistedReducer = persistReducer(persistConfig, RootReducer);

const logger = createLogger({
  predicate: () => 'development' !== 'development',
});

const enhancer = compose(applyMiddleware(thunk, logger));
/**
 * create new store
 */
const store: any = createStore(persistedReducer, composeWithDevTools(
  // applyMiddleware(...middleware),
  // other store enhancers if any
  enhancer,
));

let persistor = persistStore(store)
//return { store, persistor }
export default { store, persistor };


// const store = createStore(reducer, composeWithDevTools(
//   applyMiddleware(...middleware),
//   // other store enhancers if any
// ));
