import React from "react";
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import './assets/vendors/mdi/css/materialdesignicons.min.css';
// import './assets/vendors/css/vendor.bundle.base.css';
// import './assets/css/style.css';

import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import axios from "axios";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*  baseURL: "http://127.0.0.1:9002/api/",
  headers: {
    "Content-Type": "application/json",
  },
});*/
const CancelToken = axios.CancelToken;

axios.interceptors.request.use(
  (request) => {
    request.headers.Authorization = "12345678";
    return {
      ...request,
      cancelToken: "",
    };
  }
  //(error)=>{
  //return Promise.reject(error);
  //}
);
/*axios.interceptors.response.use(
    config => {
        const token = localStorage.getItem('auth_token');
        console.log("config"+config.headers);
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
});*/
