import React, { useState, useEffect } from "react";
import uplodIcon from "../../../assets/images/profile/upload-icon.png";
import CloseIcon from "@mui/icons-material/Close";
import "./UsersDetail.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MetricScore from "../../../assets/images/users-detail/my-metric-score.svg";
import ActivityScore from "../../../assets/images/users-detail/activity-score.svg";
import Calories from "../../../assets/images/users-detail/calories.svg";
import Sleep from "../../../assets/images/users-detail/sleep.svg";
import Steps from "../../../assets/images/users-detail/steps.svg";
import BlockPopup from "../../../components/blockpopup/blockpopup";
import UnblockPopup from "../../../components/unblockpopup/unblockpopup";
import EditProfileDialog from "../../../components/profile/edit-profile-popup/edit-popup";
import Userimg from "../../../assets/images/profile/profile-img.png";
import axiosPrivateApi from "../../../config/axiosPrivate";
import { getStatusText, getDateOrAge } from "../../../utils/Profile";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from '@mui/material/Stack';


const UsersDetail = (props) => {
  const [value, setValue] = useState("day");
  const [statistic, setStatisticData] = useState({});
  const [changeStatus, setUpdateStatus] = useState(false);
  const [profile, setProfileData] = useState([
    (FirstName) => "N/A",
    (LastName) => "N/A",
  ]);
  const id = props.match.params.id;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log("handleChange",newValue);
  };

  const [openBlock, setBlockOpen] = useState(false);
  const handleBlockClickOpen = () => {
    setBlockOpen(true);
  };

  const [openUnblock, setUnblockOpen] = useState(false);
  const handleUnblockClickOpen = () => {
    setUnblockOpen(true);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const updateStatus = (childData)=>{
    setUpdateStatus(childData);
  }


  useEffect(async () => {
    await axiosPrivateApi
      .get("/admin/user/" + id+"?timeline="+value)
      .then((res) => {
        setProfileData(res.data.data.user);
        setStatisticData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [changeStatus,value]);




  return (
    <div className="user-profile-wrap">
      <div className="profile-detail-inner">
        <div className="breadcrumb-wrap">
          <div className="breadcrumb-left">
            <h2 className="sub-title">User Details</h2>
          </div>
          <div className="breadcrumb-right">
            {/* <div className="edit-btn">
              <Button variant="contained" onClick={handleClickOpen}>
                Edit
              </Button>
            </div> */}

            {( profile.IsActive == 1  && <div className="block-btn">  <Button variant="outlined" color="error" onClick={() => handleBlockClickOpen()}> Block</Button></div>)  }

            {( profile.IsActive == 2  && <div className="block-btn">  <Button variant="outlined" color="success" onClick={() => handleUnblockClickOpen()}> Unblock</Button></div>)  }

          </div>
        </div>
        <div className="user-profile-info">
          <div className="upload-wrap">
            <div className="upload-file" style={{ display: "none" }}>
              <input type="file" name="profileImage" />
              <div className="upload-icon">
                <figure>
                  <img src={uplodIcon} alt="Upload Icon" />
                </figure>
                <p>Upload Image</p>
              </div>
            </div>
            <div className="upload-image-2">
              <figure>
                <img src={Userimg} alt="User Icon" />
              </figure>
              {/* <div className="close-icon">
                <CloseIcon />
              </div> */}
            </div>
          </div>

          <div className="col-right">
            <div className="user-info">
              <label>Name:</label>
              <p>{profile.FirstName + " " + profile.LastName}</p>
            </div>
            <div className="user-info">
              <label>Email ID: </label>
              <p>{profile.Email}</p>
            </div>
            <div className="user-info">
              <label>Phone number:</label>
              <p>{profile.PhoneNumber}</p>
            </div>
            <div className="user-info">
              <label>Registered On :</label>
              <p>{getDateOrAge(profile.AccountActivationDate)}</p>
            </div>
            {/* <div className="user-info">
              <label>Title:</label>
              <p>Admin</p>
            </div> */}
            <div className="user-info">
              <label>Age:</label>
              <p>{profile.Dob ? getDateOrAge(profile.Dob, true) : "N/A"}</p>
            </div>
            <div className="user-info">
              <label>Status:</label>
              <p>{ getStatusText(profile.IsActive)}</p>
            </div>
            <div className="user-info">
              <label>Device Id:</label>
              <p>MP73283747</p>
            </div>
          </div>
        </div>
        {/* <div className="register-id">
          <div className="user-info">
            <label>Registered On :</label>
            <p>{getDateOrAge(profile.AccountActivationDate)}</p>
          </div>
        </div> */}
      </div>
      <div className="tabs-section">

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box>
              <TabList onChange={handleChange}>
                <Tab label="Day" value="day" />
                <Tab label="Week" value="week" />
                <Tab label="Month" value="month" />
                <Tab label="Year" value="year" />
              </TabList>
            </Box>
            <TabPanel value={value} >
              <div className="tab-content">
                <div className="tab-row">
                  <div className="tab-col">
                    <div className="tab-box">
                      <div className="tab-box-inner">
                        <div className="tab-left">
                          <h2 className="tab-info">{statistic.metricScore}</h2>
                          <p className="tab-description">My Metric Score</p>
                        </div>
                        <div className="tab-right">
                          <div className="tab-image">
                            <figure>
                              <img src={MetricScore} alt="Image" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-col">
                    <div className="tab-box">
                      <div className="tab-box-inner">
                        <div className="tab-left">
                          <h2 className="tab-info">{statistic.calories}</h2>
                          <p className="tab-description">Calories</p>
                        </div>

                        <div className="tab-right">
                          <div className="tab-image">
                            <figure>
                              <img src={Calories} alt="Image" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-col">
                    <div className="tab-box">
                      <div className="tab-box-inner">
                        <div className="tab-left">
                          <h2 className="tab-info">{statistic.activityScore}</h2>
                          <p className="tab-description">Activity Score</p>
                        </div>
                        <div className="tab-right">
                          <div className="tab-image">
                            <figure>
                              <img src={ActivityScore} alt="Image" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-col">
                    <div className="tab-box">
                      <div className="tab-box-inner">
                        <div className="tab-left">
                          <h2 className="tab-info">{statistic.steps}</h2>
                          <p className="tab-description">Steps</p>
                        </div>
                        <div className="tab-right">
                          <div className="tab-image">
                            <figure>
                              <img src={Steps} alt="Image" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-col">
                    <div className="tab-box">
                      <div className="tab-box-inner">
                        <div className="tab-left">

                          <h2 className="tab-info">{statistic.sleepScore}</h2>
                          <p className="tab-description">Sleep Score</p>

                        </div>
                        
                        <div className="tab-right">
                          <div className="tab-image">
                            <figure>
                              <img src={Sleep} alt="Image" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-col">
                    <div className="tab-box">
                      <div className="tab-box-inner">
                        <div className="tab-left">
                          <h2 className="tab-info">{statistic.miles}</h2>
                          <p className="tab-description">Miles</p>
                        </div>
                        <div className="tab-right">
                          <div className="tab-image">
                            <figure>
                              <img src={Steps} alt="Image" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

          </TabContext>
        </Box>
      </div>
      <div className="block-alert">
        <BlockPopup profileData={profile}  parentCallback={updateStatus} open={openBlock} setOpen={setBlockOpen} />
      </div>
      <div className="unblock-alert">
        <UnblockPopup profileData={profile} open={openUnblock} parentCallback={updateStatus} setOpen={setUnblockOpen} />
      </div>
      <div className="edit-profile-popup">
        <EditProfileDialog open={open} setOpen={setOpen} />
      </div>
    </div>
  );
};

export default UsersDetail;
