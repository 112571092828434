import React, { useState, useEffect } from "react";
import "../../assets/css/dashboard.css";
import ActiveUser from "../../assets/images/dashboard/img-5.png";
import BlockUser from "../../assets/images/dashboard/block-user.png";
import instance from "../../config/axiosProtectedConfig";
import axiosPrivateApi from "../../config/axiosPrivate";

function Dashboard() {
  const [userCount, setUserCount] = useState({
    totalUsers: 0,
    activeUsers: 0,
    blockedUsers: 0,
  });

  useEffect(async () => {
    await axiosPrivateApi
      .get("/admin/dashboard")
      .then((response) => {
        if (response.data.statusCode == 200) {
          setUserCount(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="dashboard-wrapper">
      {/* <h3 className="section-title">Dashboard Management</h3> */}
      <div className="card-section dashboard-card">
        <div className="card-row">
          <div className="card-col">
            <div className="card-box">
              <div className="card-box-inner">
                <div className="card-left">
                  <h2 className="card-info">{userCount.totalUsers}</h2>
                  <p className="card-description">Total Users</p>
                </div>
                <div className="card-right">
                  <div className="card-image">
                    <figure>
                      <img src={ActiveUser} alt="Image" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-col">
            <div className="card-box">
              <div className="card-box-inner">
                <div className="card-left">
                  <h2 className="card-info">{userCount.activeUsers}</h2>
                  <p className="card-description">Active Users</p>
                </div>
                <div className="card-right">
                  <div className="card-image">
                    <figure>
                      <img src={ActiveUser} alt="Image" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-col">
            <div className="card-box">
              <div className="card-box-inner">
                <div className="card-left">
                  <h2 className="card-info">{userCount.blockedUsers}</h2>
                  <p className="card-description">Blocked Users</p>
                </div>
                <div className="card-right">
                  <div className="card-image">
                    <figure>
                      <img src={BlockUser} alt="Image" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="firebase-text">
        <h2 className="firebase-head">Firebase Stats </h2>
        <a href='https://console.firebase.google.com/project/eisai-ewb/overview' target='_blank' className="firebase-link">Click here</a>
      </div>
    </div>
  );
}

export default Dashboard;
