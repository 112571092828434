import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import TablePagination from '@mui/material/TablePagination';
import { useSelector,useDispatch } from 'react-redux'

import "./pagination.css";
  var pageArr = [];
const PaginationSection = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    var [pages, setPages] = React.useState(-1);

    const dispatch = useDispatch();

    const paginationInfo = useSelector((state) => ((props.page == 'notification') ? state.notificationReducer :state.paginationReducer) );

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handlePrevPagination = (event) => {
      if (pages > 0) {
          var data = {
            lastEvaulatedKey:paginationInfo.previous.lastEvaulatedKey,
            lastEvaulatedTime:paginationInfo.previous.lastEvaulatedTime,
            isFirstTime:(pages == 0 ? 0 : 1)
          }
          setPages(--pages);
          props.parentCallback(pageArr[pages]);
        }else{
          props.parentCallback({isFirstTime:false});
          setPages(--pages);
        }
    };

    const handleNextPagination = (event) => {

      if (paginationInfo.lastPage == false) {
            setPages(++pages);
            paginationInfo.isFirstTime = 1;
            if (!checkKeyExist(pageArr,paginationInfo.lastEvaulatedKey)) {
                pageArr.push(paginationInfo);
            }
            props.parentCallback(pageArr[pages]);
      }
    };

    function checkKeyExist(arr,key){
      return arr.some(function(el) {
        return el.lastEvaulatedKey == key ? true : false;
      });
    }


    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  return (
    <div className="pagination-wrap">
      {/* <p>
        Page: <b>{page}</b>
      </p>
      <div className="pagination">
        <Stack spacing={2}>
          <Pagination count={10} page={page} onChange={handleChange} />
        </Stack>
      </div> */}
      <div className="pagination">
        <div className={"left-arrow "+(pages == -1 ? "disable" : "enable") } onClick={handlePrevPagination}>
          <IconButton disabled={ pages == -1 ? true : false}>
            <KeyboardArrowLeftIcon    />
          </IconButton>
        </div>
        <div style={{ 'display':"none" }} className="pagination-sec">
          <Pagination color="primary" count={2} />
        </div>
        <div className={"right-arrow "+(!paginationInfo.lastPage ? "enable" : "disable")} onClick={handleNextPagination} >
          <IconButton disabled={!paginationInfo.lastPage ? false : true} >
            <ChevronRightIcon     />
          </IconButton>
        </div>
      </div>
      {/* <div className="filterWrap">
        <div className="countWrap">
          <p>
            0-<b>10</b> of <b>50</b>
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default PaginationSection;
