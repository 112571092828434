import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ColumnSort from "../../../components/columnsort/Columnsort";
import { Link } from "react-router-dom";
import UserIcon from "../../../assets/images/users/user-icon.png";
import DeletePopup from "../../../components/deletepopup/deletepopup";
import BlockPopup from "../../../components/blockpopup/blockpopup";
import UnblockPopup from "../../../components/unblockpopup/unblockpopup";
import "./users-list.css";
import axiosPrivateApi from "../../../config/axiosPrivate";
import { getStatusText, getDateOrAge } from "../../../utils/Profile";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../components/loader/loader";

const UserTableList = (props) => {
  const [openDelete, setDeleteOpen] = useState(false);
  const [userListing, setUserList] = useState([]);
  const [changeStatus, setUpdateStatus] = useState("");

  const [processing, setProcessing] = useState(false);

  const [profile, setProfileData] = useState([]);

  const dispatch = useDispatch();

  const paginationInfo = useSelector((state) => state.paginationReducer);

  const handleDeleteClickOpen = (e, data) => {
    setProfileData(data);
    setDeleteOpen(true);
  };
  var filterInput = props.data.input;
  var filterParam = {
    limit: filterInput.limit,
    search: filterInput.search,
    //lastEvaulatedKey:'',
    //ageGroup:filterInput.ageGroup,
    //status:filterInput.status,
    //  lastEvaulatedTime:''
  };
  if (filterInput.ageGroup) {
    filterParam.ageGroup = filterInput.ageGroup;
  }
  if (filterInput.status != -1) {
    filterParam.status = filterInput.status;
  }
  if (filterInput.location) {
    filterParam.location = filterInput.location;
  }
  var srNo = 0;

  const [openBlock, setBlockOpen] = useState(false);
  const handleBlockClickOpen = (e, data) => {
    setProfileData(data);
    setBlockOpen(true);
  };

  const [openUnblock, setUnblockOpen] = useState(false);
  const handleUnblockClickOpen = (e, data) => {
    setProfileData(data);
    setUnblockOpen(true);
  };

  const updateStatus = (childData) => {
    setUpdateStatus(childData);
    setBlockOpen(false);
  };

  useEffect(async () => {
    if (props.data.isSubmited || changeStatus) {
      setProcessing(true);
      if (props.usersList.isFirstTime != undefined || props.usersList.isFirstTime) {
        filterParam["lastEvaulatedKey"] = props.usersList.lastEvaulatedKey;
        filterParam["lastEvaulatedTime"] = props.usersList.lastEvaulatedTime;
      }

      await axiosPrivateApi
        .get("/admin/users", {
          params: filterParam,
        })
        .then((res) => {
          setProcessing(false);
          if (res.data.statusCode == 200) {
            setUserList(res.data.data.users);
            res.data.data.meta.isFirstTime = -1;
            res.data.data.meta.lastPage =
              res.data.data.meta.lastEvaulatedTime == undefined ? true : false;
            //console.log("res.data.data.meta====>",res.data.data.meta);
            dispatch({ type: "NEXT_PAGINATION", payload: res.data.data.meta });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props, changeStatus]);

  return (
    <div className="user-main-wrapper">
      <div className="table-wrapper user-list">
        {processing && <Loader />}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>
                  <div className="sort-outer">
                    <span>Name</span>
                    {/*<ColumnSort />*/}
                  </div>
                </TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Age</TableCell>
                <TableCell>User Type</TableCell>
                <TableCell>
                  <div className="sort-outer">
                    <span>Reg On</span>
                    {/*<ColumnSort />*/}
                  </div>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userListing.map((row) => (
                <TableRow key={row.Id}>
                  <TableCell>{++srNo}</TableCell>
                  <TableCell>
                    <div className="user-info-sec">
                      <div className="user-image">
                        <figure>
                          <img src={UserIcon} alt="Image" />
                        </figure>
                      </div>
                      <Link to={"users/detail/" + row.Id}>
                        {row.FirstName}
                        {row.LastName}
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>{row.PhoneNumber}</TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>
                    {row.Dob ? getDateOrAge(row.Dob, true) : "N/A"}
                  </TableCell>
                  <TableCell
                    className={
                      "status-" + (row.IsActive == 1 ? "active" : "block")
                    }
                  >
                    {getStatusText(row.IsActive)}
                  </TableCell>
                  <TableCell>
                    {getDateOrAge(row.AccountActivationDate)}
                  </TableCell>
                  <TableCell>
                    <div className="action-wrap">
                      { ( !([0,3].includes(row.IsActive))  )  &&
                        <Tooltip title="Delete">
                          <div onClick={(e) => handleDeleteClickOpen(e,row)} >
                          <IconButton color="error">
                            <DeleteIcon />
                          </IconButton>
                          </div>
                        </Tooltip>
                      }

                      { row.IsActive == 1 && <Tooltip title="Block">
                        <div   onClick={(e) => handleBlockClickOpen(e,row)} >
                          <IconButton color="error" >
                            <BlockIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                      }
                      {row.IsActive == 2 && (
                        <Tooltip title="Unblock">
                          <div onClick={(e) => handleUnblockClickOpen(e, row)}>
                            <IconButton>
                              <CheckCircleOutlineIcon color="success" />
                            </IconButton>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {userListing.length == 0 && (
                <TableRow>
                  <TableCell colSpan="7">No data found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="unblock-alert">
        <DeletePopup
          open={openDelete}
          profileData={profile}
          parentCallback={updateStatus}
          setOpen={setDeleteOpen}
        />
      </div>
      <div className="block-alert">
        <BlockPopup
          profileData={profile}
          parentCallback={updateStatus}
          open={openBlock}
          setOpen={setBlockOpen}
        />
      </div>
      <div className="unblock-alert">
        <UnblockPopup
          profileData={profile}
          open={openUnblock}
          parentCallback={updateStatus}
          setOpen={setUnblockOpen}
        />
      </div>
    </div>
  );
};

export default UserTableList;
