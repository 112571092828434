import "./TermsConditions.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
const TermsConditions = () => {
  const [termsCondHtml, setTermsCond] = useState("");

  useEffect(() => {
    const URL = process.env.REACT_APP_BASE_URL;
    //let BASE_URL = URL.replace("admin/", "");
    axios
      .get(URL + "/terms-conditions")
      .then((response) => {
        setTermsCond(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
    { !termsCondHtml && <div style={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)'}}>
        <CircularProgress size={100}  />
    </div> }
    <div
      className="content-description-terms"
      dangerouslySetInnerHTML={{ __html: termsCondHtml }}
    ></div>
  </>
  );
};

export default TermsConditions;
