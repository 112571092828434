import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import NotificationList from "./list/NotificationList";
import PaginationSection from "../../components/pagination/pagination";
import DatePicker from "@mui/lab/DatePicker";
import "./Notification.css";
import { getStatusText, getDateOrAge } from "../../utils/Profile";

const Notification = () => {
  var defaultInput = {input:{ search:"", limit: 10, platform: '', status:'',fromDate:'',toDate:'',isSubmited:true }};
  const [from, setFrom] = useState([null, null]);
  const [to, setTo] = useState([null, null]);
  const [show, setShow] = useState("");
  const [status, setStatus] = useState("");
  const [platform, setPlatform] = useState("");
  const [inputValues, setInputValues] = useState(defaultInput);

  const [dateMsg, setDateMsg] = useState("");

  const [filterParams, setFilterParam] = useState("");

  const handleChange = (e) => {

    let input = inputValues.input;
    input[e.target.name] = e.target.value;
    input['isSubmited'] = false;
    setInputValues((prev)=>({...prev,input:input}));
    if (e.target.name == "limit") {
      setShow(e.target.value);
    } else if (e.target.name == "platform") {
      setPlatform(e.target.value);
    } else if (e.target.name == "status") {
      setStatus(e.target.value);
    }
  };

  const handleCallback = (childData)=>{
      //setisSubmited(true);
      setFilterParam(childData);
      //setSetOffset(childData);
  };

  const handleSubmit = (e)=>{
    e.preventDefault();
    setDateMsg("");
    if (inputValues.input.fromdate > inputValues.input.toDate ) {
      setDateMsg("To date can not be smaller than From date");
    }
    setInputValues((prev)=>({...prev,input:{...prev.input,isSubmited:true} }));
  }

  const reset = ()=>{
    setInputValues(defaultInput);
  }

  return (
    <div className="notification-wrap">
      <div className="breadcrumb-wrap notification-btn">
        <Link to="/notification/add">
          <Button variant="contained">Add notification</Button>
        </Link>
      </div>
      <div className="filter-area">

        <form onSubmit={handleSubmit} >
        <div className="filter-top-content">
          <div className="search-box">
            <TextField
              placeholder="Search through title or description"
              type="text"
              name="search"
              onChange={handleChange}
              value={inputValues.input.search}
            />
          </div>
          <div className="select-box">
            <FormControl fullWidth>
              <Select value={inputValues.input.limit} onChange={handleChange} name='limit' displayEmpty>

                <MenuItem value={10}>Show 10</MenuItem>
                <MenuItem value={50}>Show 50</MenuItem>
                <MenuItem value={100}>Show 100</MenuItem>
                <MenuItem value={500}>Show 500</MenuItem>
                <MenuItem value={1000}>Show 1000</MenuItem>
                <MenuItem value={10000}>Show 10000</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="filter-bottom-content">
          <div className="select-box">
            <div className="date-picker">
              <label className="form-label">Sent On</label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={from}
                  format="MM/dd/yyyy"
                  name="fromDate"
                  onChange={(newValue) => {
                    var date = new Date(newValue);
                    //var date1 = date.getTime()/1000;
                    var date1 = date.toISOString().slice(0,10);
                    console.log("date1",date1);
                    setInputValues((prev)=>({...prev,input:{...prev.input,fromDate:date1,isSubmited:false} }));
                    setFrom(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            { dateMsg ? <span className="error-msg">
              {dateMsg}
            </span>: "" }
          </div>
          <div className="select-box top-spacing">
            <div className="date-picker">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={to}
                  name="toDate"
                  onChange={(newValue) => {
                    var todate = new Date(newValue);
                    //var todate1 = todate.getTime()/1000;
                    var todate1 = todate.toISOString().slice(0,10);
                    setInputValues((prev)=>({...prev,input:{...prev.input,toDate:todate1,isSubmited:false} }));
                    setTo(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            {/* <span className="error-msg">
              To date can not be smaller than From date
            </span> */}
          </div>
          <div className="select-box">
            <label className="form-label">Status</label>
            <FormControl fullWidth>
              <Select
                placeholder="Select"
                value={inputValues.input.status}
                name="status"
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="">Select Status</MenuItem>
                <MenuItem value={1}>Sent</MenuItem>
                <MenuItem value={0}>Draft</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="select-box">
            <label className="form-label">Platform</label>
            <FormControl fullWidth>
              <Select
                placeholder="Select"
                value={inputValues.input.platform}
                name="platform"
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="">Select Platform</MenuItem>
                <MenuItem value={1}>Android</MenuItem>
                <MenuItem value={2}>IOS</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="btn-area">
          <Button variant="contained" type='submit'>Apply</Button>
          <div className="reset-btn">
            <Button variant="outlined" onClick={reset} color="error">
              Reset
            </Button>
          </div>
        </div>
      </form>

      </div>
      <div className="main-section-wrap">
        <NotificationList data={inputValues} params={filterParams} page="notification" />
        <PaginationSection  parentCallback={handleCallback}  page="notification" />
      </div>
    </div>
  );
};

export default Notification;
