import React, { useState ,useEffect } from "react";
// import "../../assets/css/user-management.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import UserTableList from "./list/users-list";
import PaginationSection from "../../components/pagination/pagination";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

import countryList from "../../json/CountryCodes.json";


const Users = () => {
  var defaultInput = { input: { search:"", limit: 10, ageGroup: "", status:"" ,location:"" },isSubmited:true };
  const [selectPerPage, setSelectPerPage] = useState(10);
  const [offset, setSetOffset] = useState(0);
  const [inputValues, setSetInputValues] = useState(defaultInput);
  const [age, setAge] = useState("18-30");
  const [user, setUser] = useState(1);
  const [location, setLocation] = useState("");
  const [isSubmited, setisSubmited] = useState(true);

  const [userList, setUserList] = useState("default offset");


  const handleCallback = (childData)=>{
      setUserList(childData);
  };

  const handleChange = (e) => {
    let input = inputValues.input;
    input[e.target.name] = e.target.value;
    setSetInputValues({ input: input,isSubmited:false });

    if (e.target.name == "limit") {
      setSelectPerPage(e.target.value);
    } else if (e.target.name == "ageGroup") {
      setAge(e.target.value);
    } else if (e.target.name == "status") {
      setUser(e.target.value);
    }else if (e.target.name == "location") {
      setLocation(e.target.value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSetInputValues((prev) => ({ ...prev,isSubmited:true }));
    console.log("inputValues",inputValues);
  };

  const handleUserCallback = (childData) => {
    console.log("handleUserCallback", childData);
  };

  const reset = ()=>{
    setSetInputValues(defaultInput);
  }
  return (
    <div className="user-wrap">
      <div className="filter-area">
        <form onSubmit={handleSubmit}>
          <div className="filter-top-content">
            <div className="search-box">
              <TextField
                type="text"
                name="search"
                onChange={handleChange}
                value={inputValues.input.search}
                placeholder="Search through Username"
              />
            </div>
            <div className="select-box">
              <FormControl fullWidth>
                <Select
                  value={inputValues.input.limit}
                  onChange={handleChange}
                  name="limit"
                  displayEmpty
                >
                  <MenuItem value={10}>Show 10</MenuItem>
                  <MenuItem value={50}>Show 50</MenuItem>
                  <MenuItem value={100}>Show 100</MenuItem>
                  <MenuItem value={500}>Show 500</MenuItem>
                  <MenuItem value={1000}>Show 1000</MenuItem>
                  <MenuItem value={10000}>Show 10000</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="filter-bottom-content">
            <div className="select-box">
              <label className="form-label">Age Group</label>
              <FormControl fullWidth>
                <Select
                  placeholder="Select"
                  value={inputValues.input.ageGroup}
                  onChange={handleChange}
                  displayEmpty
                  name="ageGroup"
                >
                  <MenuItem value="">Select Age Group</MenuItem>
                  <MenuItem value={"18-30"}>18-30</MenuItem>
                  <MenuItem value={"30-50"}>30-50</MenuItem>
                  <MenuItem value={"50-70"}>50-70</MenuItem>
                  <MenuItem value={"70-"}>70+</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="select-box">
              <label className="form-label">Location</label>
              <FormControl fullWidth>
                <Select
                  placeholder="Select"
                  value={inputValues.input.location}
                  onChange={handleChange}
                  name="location"
                  displayEmpty
                >
                  <MenuItem value="">Select Location</MenuItem>
                  {
                    countryList.map((data,index)=>{
                      return (
                        <MenuItem key={index} value={data.code}>{data.name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </div>
            <div className="select-box">
              <label className="form-label">User Type</label>
              <FormControl fullWidth>
                <Select
                  placeholder="Select"
                  value={inputValues.input.status}
                  onChange={handleChange}
                  displayEmpty
                  name="status"
                >
                  <MenuItem value="">Select Status</MenuItem>
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>In Progress</MenuItem>
                  <MenuItem value={2}>Blocked</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="btn-area">
            <Button variant="contained" type="submit">
              Apply
            </Button>
            <div className="reset-btn">
              <Button variant="outlined" color="error" onClick={reset}>
                Reset
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className="main-section-wrap">
        <UserTableList data={inputValues} parentUserCallback={handleUserCallback} usersList={userList} page="userList" />
        <PaginationSection parentCallback={handleCallback} page="userList" />
      </div>
    </div>
  );
};

export default Users;
