import axios from 'axios';
export const getS3SignUrl = async (filename, filetype) => {
    let access_token = localStorage.getItem('idToken');
    const headers = new Headers({ 'Authorization': access_token
      });
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const options = {
    method: 'GET',
    headers: headers,
    //body: JSON.stringify({ fileName, fileType: fileType })
     };
    const response = await fetch(`${BASE_URL}/admin/presigned-url?fileName=`+filename,
    options);
    const presignedUrl = await response.json();
    return presignedUrl
}

export const pushProfilePhotoToS3 = async (presignedUrl, file)=>{
      //const myHeaders = new Headers({ 'ContentType': 'image/*' });
        const Headers = {'ACL': 'public-read'};
        console.log("Headers",Headers);
        const response = await fetch(presignedUrl, {
            method: 'PUT',
            headers:Headers ,
            body: file
         });
         return response;
}

export const getDateOrAge =  (timestamp,age=false) => {
   timestamp = parseInt(timestamp);
   var date  = new Date(timestamp * 1000).toLocaleDateString('en-US');

   if (age) {
      const millis = Date.now() - Date.parse(date);
      return new Date(millis).getFullYear() - 1970;
    }
    return date;
}

 export const getStatusText =  (status)=>{
  switch (status) {
    case 0:
      return "In Progress";
      break;
    case 1:
      return "Active";
      break;
    case 2:
      return "Blocked"
      break;
    case 3:
        return "Deleted"
        break;
    default:
  }
}

export const plateformType =  (plateformType) => {
  if (plateformType.length == 2) {
      return "All";
  }
  if (plateformType.length == 1 && plateformType[0] == 1) {
      return "Android";
  }
  if (plateformType.length == 1 && plateformType[0] == 2) {
      return "IOS";
  }
  return "N/A";
}
