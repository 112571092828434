import React from "react";

import Layouts from "../pages/common/Layouts";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../pages/login/Login";
import Logout from "../pages/login/Logout";
import Forgotpassword from "../pages/login/Forgotpassword";
import Resetpassword from "../pages/login/Resetpassword";
import ForgotResetPassword from "../pages/login/ForgotResetPassword";
import Profile from "../pages/profile/profile";
import Users from "../pages/user-management/Index";
import UsersDetail from "../pages/user-management/usersdetail/UsersDetail";
import PrivacyPolicy from "../pages/content-management/privacy-policy/PrivacyPolicy";
import TermsConditions from "../pages/content-management/terms-conditions/TermsConditions";
import Faq from "../pages/content-management/faq/Faq";
import Private from "./Private";
import Public from "./Public";
import Notification from "../pages/notification-management/Notification";
import NotificationAdd from "../pages/notification-management/notification-add/NotificationAdd";

const webpage = () => {
  const userAuthentication = localStorage.getItem("authStatus");
  return (
    <Router>
      <Switch>
        <Public exact component={Login} path="/"  />
        <Public exact component={Login} path="/login"  />
        <Public exact component={Forgotpassword} path="/forgot-password" />
        <Public exact component={Resetpassword} path="/reset-password" />
        <Public
          exact
          component={ForgotResetPassword}
          path="/admin/reset-password"
        />

        <Private exact component={UsersDetail} path="/users/detail/:id" title="User Details" />
        <Private exact component={PrivacyPolicy} path="/content/privacy-policy" title="Privacy Policy" />
        <Private exact component={TermsConditions} path="/content/terms-conditions" title="Terms Conditions" />
        <Private exact component={Faq} path="/content/faq" title="Faq" />
        <Private exact component={Notification} path="/notification" title="Notification Management" />
        <Private exact component={NotificationAdd} path="/notification/add" title="Notification Management" />
        <Private exact component={Dashboard} path="/dashboard" title="Dashboard" />
        <Private exact component={Profile} path="/profile" title="Profile Details" />
        <Private exact component={Users} path="/users" title="User Management" />
        <Route exact path="/logout" ><Logout /> </Route>

        <Route path="*" component={()=>{ return <div>Not Found</div> }}  ></Route>
      </Switch>
    </Router>
  );
};

export default webpage;
