const intialState = {
}
const reducer  = (state=intialState ,  action: any) =>{
  switch (action.type) {

    case "NEXT_PAGINATION":
      //return {state , ...action.payload };
      return {...action.payload,previous:{...state},tags: action.payload };
      break;
    default:
    return state;
  }
}
export default reducer;
