import React from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebarlogo from "../../assets/images/sidebar/sidebar-logo.png";
import "../../assets/css/navbar.css";
import { connect } from "react-redux";
import Adminimg from "../../assets/images/admin1.png";

function Navbar(props) {
  const location = useLocation();

  const [contentDropdown, setShowContentDrodown] = React.useState(false);

  const handleContentManagment = (e) => {
    e.preventDefault();
    setShowContentDrodown((contentDropdown) => !contentDropdown);
  };


  let pathsArray = ['/content/privacy-policy','/content/terms-conditions','/content/faq'];
  let path = pathsArray.includes(location.pathname);

  const closeContentMenu = (e)=>{
    path = false;
    setShowContentDrodown(false);
  }

  let baseURL = process.env.REACT_APP_BASE_URL;
  let upDownIcon = (contentDropdown || path ? "down-arrow" : "up-arrow");
  let isActive = (contentDropdown || path ? "content-inactive" : "content-active");
  return (
    <aside className={"sidebar" + (props.status ? " sidebar-width" : "")}>
      <div className="sidebarInner">
        <div className="sidebar-header">
          <div className="sidebar-logo">
            <Link to="/dashboard">
              <figure>
                <img src={ localStorage.getItem("picture") ? localStorage.getItem("picture") : Adminimg } alt="Logo" />
              </figure>
            </Link>
          </div>
          <div className="sidebar-title">
            <h2>EWB</h2>
          </div>
        </div>
        <div className="sidebar-main">
          <ul className="sidebar-menu">
            <li>
              <Link
                to="/dashboard" onClick={closeContentMenu}
                className={
                  location.pathname == "/dashboard"
                    ? " dashboard active"
                    : "dashboard"
                }
              >
                <i className="icon dashboard"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to="/users"
                className={
                  location.pathname == "/users" ? " user active" : "user"
                }
                onClick={closeContentMenu}
              >
                <i className="icon user"></i>
                <span>User Management</span>
              </Link>
            </li>
            <li className={"parent-menu  " + upDownIcon}>
              <Link
                to="#"
                className= { path ? "arrow active" : "arrow" }
                onClick={(e) => handleContentManagment(e)}
              >
                <i className="icon content"></i>
                <span>Content Management</span>
              </Link>
              <ul className={"sub-menu " + isActive}>
                <li>
                  <Link to="/content/privacy-policy" className={location.pathname == "/content/privacy-policy" ? "active" : ""} >
                    <i className="icon privacy-policy"></i>
                    <span>Privacy Policy</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/terms-conditions" className={location.pathname == "/content/terms-conditions" ? "active" : ""}>
                    <i className="icon terms-conditions"></i>
                    <span>Terms & Condition</span>
                  </Link>
                </li>
                <li>
                  <a href={baseURL+'/faq'} target='_blank' className={location.pathname == "/content/faq" ? "active" : ""}>
                    <i className="icon faq"></i>
                    <span>FAQs</span>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/notification" onClick={closeContentMenu} className={
                location.pathname == "/notification"
                  ? " notification active"
                  : "notification"

              }>
                <i className="icon notification"></i>
                <span>Notification Management</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
}

function mapStateToProps(state) {
  return {status:state.sideBarReducer.status,profileDetails:state.profileReducer};
}

export default connect(mapStateToProps)(Navbar);
