import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";
import "./NotificationBreadcrumb.css";

const NotificationBreadcrumb = () => {
  return (
    <div className="notification-breadcrumb">
      <Link to="/notification">
        <span className="section-notification-title">
          Notification Management
        </span>
      </Link>
      <ArrowBackIosNewIcon />
      <Link to="/notification/add">
        <span className="section-sub-title">Add notification</span>
      </Link>
    </div>
  );
};

export default NotificationBreadcrumb;
