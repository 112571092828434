import React, { useState, useEffect } from "react";
import AccountLeftSection from "../../components/login/account-left-section/account-left";
import "../../assets/css/login.css";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import Error from "../../assets/images/login/error.png";
import Button from "@mui/material/Button";
import { useHistory, useParams } from "react-router-dom";
import axiosConfig from "../../config/axiosConfig";
import SetSession from "../../utils/Session";
import { useDispatch } from "react-redux";

function Login() {
  var states = {
    input: {},
    errors: {},
  };
  const [state, setValue] = useState(states);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setShowError] = useState("");

  const [checked, setChecked] = useState(true);

  const [loader, setLoader] = useState(false);
  const history = useHistory();
  let dispatch = useDispatch();

  useEffect(() => {
    const loggedInUser = localStorage.getItem("authStatus");
  }, []);

  const handleChange = (e) => {
    let input = state.input;
    input[e.target.name] = e.target.value;
    setValue((prevState) => ({ ...prevState, input: input }));
  };

  const handleChecked = (e) => {
    setChecked(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(e)) {
      loginuser(state.input);
    }
  };

  function loginuser(credentials) {
    setLoader(true);
    delete credentials.remember_me;
    axiosConfig
      .post("/admin/login", credentials)
      .then((response) => {
        setShowError("");
        setLoader(false);
        if (response.data.statusCode == 205) {
          history.push({ pathname: "/reset-password", state: state });
        } else {
          let userInfo = response.data.data;
          window.localStorage.clear();
          window.localStorage.setItem("authStatus", 1);
          window.localStorage.setItem("accessToken", userInfo.accessToken);
          window.localStorage.setItem("refreshToken", userInfo.refreshToken);
          window.localStorage.setItem("idToken", userInfo.idToken);
          window.localStorage.setItem("userId", userInfo.user.userId);
          window.localStorage.setItem("email", userInfo.user.email);
          window.localStorage.setItem("name", userInfo.user.name);
          window.localStorage.setItem("picture", userInfo.user.picture);

          sessionStorage.setItem("accessToken", userInfo.accessToken);
          sessionStorage.setItem("refreshToken", userInfo.refreshToken);
          sessionStorage.setItem("idToken", userInfo.idToken);

          if (checked) {
            window.localStorage.setItem("remember_me", 1);
          } else {
            window.localStorage.setItem("remember_me", 0);
          }
          dispatch({ type: "PROFILE_DETAILS", payload: userInfo.user });
          //SetSession(userInfo);
          history.push("/dashboard");
        }
      })
      .catch((err) => {
        setLoader(false);
        setShowError(err.response.data.message);
      });
  }

  const validate = (e) => {
    let errors = {};
    errors["email"] = "";
    errors["password"] = "";
    let isValid = true;
    if (state.input.email == undefined || state.input.email == "") {
      isValid = false;
      errors["email"] = "Please enter your email address.";
    }
    if (state.input.password == undefined || state.input.password == "") {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }

    setValue((prevState) => ({ ...prevState, errors: errors }));
    return isValid;
  };

  const handleTogglePassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  return (
    <div className="accountWrapper">
      <AccountLeftSection />
      <div className="right-section">
        <div className="right-inner">
          <h1 className="page-title">Sign In to Admin Panel</h1>
          <p className="page-description">Enter the details below</p>
          <div className="form-wrapper">
            <form onSubmit={handleSubmit}>
              <div className="input-row">
                <label>*Email Address</label>
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  onChange={(e) => handleChange(e)}
                  value={states.input.email}
                />
                {state.errors.email && (
                  <span className="error-msg">{state.errors.email}</span>
                )}
              </div>
              <div className="password-row">
                <div className="form-group">
                  <label>*Password</label>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    password="password"
                    placeholder="Enter Your Password"
                    onChange={(e) => handleChange(e)}
                    value={states.input.password}
                  />
                  <div className="eye-icon">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                  {state.errors.password && (
                    <span className="error-msg">{state.errors.password}</span>
                  )}
                </div>
              </div>

              <div className="forgot-link">
                <Link to="/forgot-password">Forgot Password?</Link>
                {error && <span className="error-msg">{error}</span>}
              </div>
              <div className="remember-me">
                <Checkbox
                  name="remember_me"
                  checked={checked}
                  onChange={(e) => handleChecked(e)}
                  value={state.input.remember_me}
                />
                <p>Remember me</p>
              </div>
              <div className="btn-wrap login-btn">
                <Button variant="contained" type="submit" disabled={loader}>
                  {loader && <CircularProgress size={22} />}
                  Login
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
