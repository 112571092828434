import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Userimg from "../../assets/images/profile/profile-img.png";
import CloseIcon from "@mui/icons-material/Close";
import uplodIcon from "../../assets/images/profile/upload-icon.png";
import "../../assets/css/profile.css";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import errIcon from "../../assets/images/profile/error-2.png";
import EditProfileDialog from "../../components/profile/edit-profile-popup/edit-popup";
import SuccessfullPasswordPopup from "../../components/profile/successfull-password-popup/SuccessfulPassword";

import axiosProtectedConfig from "../../config/axiosProtectedConfig";
import axiosPrivateApi from "../../config/axiosPrivate";
import CircularProgress from "@mui/material/CircularProgress";
import { getS3SignUrl, pushProfilePhotoToS3 } from "../../utils/Profile";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";


const Profile = (props) => {
  const [open, setOpen] = useState(false);
  var states = {
    input: {},
    errors: {},
  };
  const [pass, setShowPassword] = useState({oldpass:false,pass:false,cpass:false});
  const [profile, setProfileData] = useState({name:'',phone_number:'',email:'',picture:''});
  const [state, setValue] = useState(states);
  const [loader, setLoader] = useState(false);

  const [resMsg, setShowMsg] = useState({ msg: "", status: false });

  const [profileImage, setShowImg] = useState({ img: null });

  const [profileUpdated, setProfileUpdate] = useState(false);

  const [profileMsg, setProfileMsg] = useState({msg:"",status:false,imgLoader:false});
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChange = (e) => {
    let input = state.input;
    input[e.target.name] = e.target.value;
    setValue((prevState) => ({ ...prevState, input: input }));
  };
  const handleTogglePassword = (e,v)=>{
      setShowPassword({[v]:!pass[v]});
  }
  const handleChangeSubmit = async (e)=>{
     e.preventDefault();
     if (validate(e)) {
       setLoader(true);
       let accessToken = localStorage.getItem('accessToken');
       let refreshToken = localStorage.getItem('refreshToken');
        await axiosPrivateApi.put('/admin/password',{
          oldPassword:state.input.oldpassword,
          newPassword:state.input.password,
          accessToken:accessToken,
          refreshToken:refreshToken
          })
        .then((response)=>{
           setLoader(false);
           setShowMsg({msg:"Password Successfully updated",status:true});
       }).catch(
          (err) => {
            setLoader(false);
            setShowMsg({msg:err.response.data.message,status:false});
           }
        );


     }
  }


  const handleCallback = (childData)=>{
    setProfileUpdate(true);
  }

  if (profileUpdated) {
    setTimeout(() => {
      setProfileUpdate(false);
    },1500);
  }

useEffect(() => {
    axiosProtectedConfig.get('/admin/profile').then((response)=>{
        setProfileData(response.data.data);
        dispatch({"type":"PROFILE_DETAILS","payload":response.data.data});
        setShowImg({img:response.data.data.picture});
    }).catch(
       (err) => {
         console.log(err);
        }
     );
},[props]);


  const validate = (e) => {
    let errors = {};
    errors["oldpassword"] = "";
    errors["password"] = "";
    errors["cpassword"] = "";
    errors["passwordLengthErr"] ="";
    let isValid = true;

    if (state.input.oldpassword == undefined || state.input.oldpassword == "") {
      isValid = false;
      errors["oldpassword"] = "Please enter your old password password.";
    }

    if (state.input.password == undefined || state.input.password == "") {
      isValid = false;
      errors["password"] = "Please enter your new password.";
    }
    if (state.input.cpassword == undefined || state.input.cpassword == "") {
      isValid = false;
      errors["cpassword"] = "Please enter your confirm password.";
    }
    if (state.input.password != state.input.cpassword) {
      isValid = false;
      errors["passwordNotMatch"] =
        "Your confirm password does not match with New password";
    }
    if (state.input.password != null ) { //
      console.log(state.input.password.length);
      if ( state.input.password.length < 8  ) {
        isValid = false;
        errors["passwordLengthErr"] = "Password must be at least 8 character.";
      }
    }

    if (!isValid) {
      setShowMsg({ msg: "", status: false });
    }

    setValue((prevState) => ({ ...prevState, errors: errors }));
    return isValid;
  };

  const [passwordopen, setPasswordOpen] = useState(false);

  const handlePasswordClickOpen = () => {
    setPasswordOpen(true);
  };
const history = useHistory();

  const changeFileHandler = async (e) => {
    setProfileMsg((prevState) => ({ ...prevState, imgLoader: true }));
    const file = e.target.files[0];
    setShowImg({ img: URL.createObjectURL(file) });
    const data = await getS3SignUrl(file["name"], file["type"]);
    if (data.data) {
      //const photoConvertedToBlob = dataURIToBlob(profilePhotoResized);
      const response = await pushProfilePhotoToS3(data.data, file);
      if (response.ok) {
        let imgUrl = response.url.split("?");
        let path = imgUrl[0];

        await axiosProtectedConfig
          .put("/admin/profile", { picture: path })
          .then((response) => {
            setProfileMsg({
              msg: "Profile Image successfuly updated",
              status: true,
              imgLoader: false,
            });
            localStorage.setItem("picture", response.data.data.picture);
            history.push('/profile');
          })
          .catch((err) => {
            setProfileMsg({
              msg: err.response.data.message,
              status: false,
              imgLoader: false,
            });
          });
      }else{
        setProfileMsg({
          msg: "Image failed to upload",
          status: false,
          imgLoader: false,
        });
        setShowImg({ img: null });
      }
    }
  };
  return (
    <div className="profile-wrap">
      <div className="profile-inner">
        <div className="breadcrumb-wrap">
          <div className="breadcrumb-left">
            <h2 className="sub-title">Profile Details</h2>
          </div>
          <div className="breadcrumb-right">
            <Button variant="contained" onClick={handleClickOpen}>
              Edit profile
            </Button>
          </div>
        </div>
        <div className="user-profile-detail">
          <div className="upload-wrap">
            <div className="upload-file ">

              <input
                type="file"
                name="profileImage"
                onChange={(e) => changeFileHandler(e)}
                accept="image/*"
              />
              <div className="upload-icon">
                <figure>
                  <img src={uplodIcon} alt="Upload Icon" />
                </figure>
                <p>Upload Image</p>
              </div>
            </div>
            <div className="upload-image-2">
              {/* content-active */}
              <figure>
                {/* <img src={Userimg} alt="User Icon" /> */}
                <img
                  src={profileImage.img ? profileImage.img : Userimg}
                  alt="User Icon"
                />
              </figure>
              <div className="close-icon">
                {profileMsg.imgLoader && <CircularProgress size={30} />}
                {/*<CloseIcon />*/}
              </div>
            </div>
          </div>
          <div className="col-right">
            <div className="profile-info">
              <label>Name:</label>
              <p>{profile.name ?? "N/A"}</p>
            </div>
            <div className="profile-info">
              <label>Email ID: </label>
              <p>{profile.email}</p>
            </div>
            <div className="profile-info">
              <label>Phone number:</label>
              <p>{profile.phoneNumber}</p>
            </div>
            <div className="profile-info">
              <label>Title:</label>
              <p>{profile.name ?? 'N/A'}</p>
            </div>{ profileUpdated ? <span className="success-msg">Profile successfully updated</span> :'' }
          </div>
        </div>

        { profileMsg.status ? <span className="success-msg">{profileMsg.msg}</span> : '' }
        { !profileMsg.status ? <span className="error-msg">{profileMsg.msg}</span> : '' }
      </div>
      <div className="change-password-wrap">
        <div className="breadcrumb-wrap">
          <h2 className="sub-title">Change Password</h2>
        </div>
        <form onSubmit={handleChangeSubmit}>
          <div className="row">
            <div className="password-col">
              <div className="form-content">
                <TextField
                  type={pass.oldpass ? "text" : "password"}
                  name="oldpassword"
                  label="*Current Password"
                  variant="outlined"
                  onChange={handleChange}
                  value={states.input.oldpassword}
                  placeholder="Enter Current Password"
                />
                <div className="eye-icon">
                  <IconButton
                    onClick={(e) => handleTogglePassword(e, "oldpass")}
                  >
                    {pass.oldpass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </div>
              </div>
              {state.errors.oldpassword ? (
                <span className="error-msg"> {state.errors.oldpassword} </span>
              ) : (
                ""
              )}
            </div>
            <div className="password-col">
              <div className="form-content">
                <TextField
                  type={pass.pass ? "text" : "password"}
                  name="password"
                  label="*New Password"
                  variant="outlined"
                  onChange={handleChange}
                  value={states.input.password}
                  placeholder="Enter New Password"
                />
                <div className="eye-icon">
                  <IconButton onClick={(e) => handleTogglePassword(e, "pass")}>
                    {pass.pass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </div>
              </div>
              {state.errors.password ? (
                <span className="error-msg"> {state.errors.password} </span>
              ) : (
                ""
              )}
            </div>
            <div className="password-col">
              <div className="form-content">
                <TextField
                  type={pass.cpass ? "text" : "password"}
                  name="cpassword"
                  label="*Confirm Password"
                  variant="outlined"
                  value={states.input.cpassword}
                  onChange={handleChange}
                  placeholder="Enter Confirm Password"
                />
                <div className="eye-icon">
                  <IconButton onClick={(e) => handleTogglePassword(e, "cpass")}>
                    {pass.cpass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </div>
                    </div>
                {  state.errors.cpassword ? <span className="error-msg"> {state.errors.cpassword} </span> : '' }
                {state.errors.passwordNotMatch ? <span className="error-msg">{state.errors.passwordNotMatch}</span>:''}
                {state.errors.passwordLengthErr ? <span className="error-msg">{state.errors.passwordLengthErr}</span>:''}

                  </div>
            </div>
            {  resMsg.msg ? <span className= {!resMsg.status ? "error-msg" : "success-msg"}> {resMsg.msg} </span> : '' }
            <div className="btn-group">

              <div className="save-btn" disabled={loader}>
                <Button variant="contained" type='submit'>{loader && (<CircularProgress size={24}/>)} Save</Button>
              </div>

            </div>

        </form>
      </div>
      <div className="edit-profile-popup">
        <EditProfileDialog open={open} profile={profile} setOpen={setOpen} parentCallback={handleCallback} />
      </div>
      <div className="successfull-password-popup">
        <SuccessfullPasswordPopup
          open={passwordopen}
          setOpen={setPasswordOpen}
        />
      </div>
    </div>
  );
};

export default Profile;
