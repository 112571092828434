import { useState, useEffect } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./Columnsort.css";

const Columnsort = (props) => {
  const [sort, setSorting] = useState(false);

  const createdDateSorting = (e, status) => {
    setSorting(status);
    console.log(sort);
    props.sortingCallback(sort);
  };

  return (
    <div className="sort-by">
      <span className="sort-by-inner">
        <div className={sort ? "sort-enable" : ''}>
          <ArrowDropUpIcon onClick={(e) => createdDateSorting(e, 1)} />
        </div>
        <div className={!sort ? "sort-enable" : ''}>
          <ArrowDropDownIcon
            onClick={(e) => createdDateSorting(e, 0)}
            disabled={true}
          />
        </div>
      </span>
    </div>
  );
};

export default Columnsort;
