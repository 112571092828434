import "./style.css";
import logo from "../../../assets/images/login/Logo.png";

const AccountLeftSection = () => {
  return (
    <div className="left-section">
      <div className="left-section-inner">
        <div className="logo">
          <figure>
            <img src={logo} alt="Logo" />
          </figure>
        </div>
        <div className="page-title-left">
          <h2 className="title bold">Copyright @2022 Eisai Inc.</h2>
          <h2 className="title ">All rights reserved.</h2>
        </div>
      </div>
    </div>
  );
};

export default AccountLeftSection;
