import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import "../../../assets/css/edit-popup.css";
import axiosProtectedConfig from "../../../config/axiosProtectedConfig";
import axiosConfig from "../../../config/axiosConfig";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EditProfileDialog = (props) => {
  const { open, addForm, setOpen, profile } = props;

  const handleClose = () => {
    setOpen(false);
  };

  var inputData = {
    input: { uname: "", phone_no: "", email: "", name: "" },
    errors: {},
  };

  const [val, setValue] = useState(inputData);
  const [successMsg, setSetMsg] = useState({ msg: "", status: false });
  const [loader, setLoader] = useState(false);

  const history = useHistory();

  const handleChange = (e) => {
    let input = val.input;
    input[e.target.name] = e.target.value;
    setValue((prevState) => ({ ...prevState, input: input }));
  };

  useEffect(() => {
    if (profile) {
      setValue({
        input: {
          uname: profile.name,
          phone_no: profile.phoneNumber,
          email: profile.email,
          name: profile.name,
        },
        errors: {},
      });
    }
  }, [profile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(e)) {
      setLoader(true);
      let postData = {
        name: val.input.uname,
        phone_number: val.input.phone_no,
      };
      axiosProtectedConfig
        .put("/admin/profile", postData)
        .then((response) => {
          setLoader(false);
          if (response.data.statusCode == 200) {
            handleClose();
            props.parentCallback(true);
            localStorage.setItem("name", postData.name);
            history.push("/profile");
          }
        })
        .catch((err) => {
          setLoader(false);
          if (err.response) {
            setSetMsg({ msg: err.response.data.message, status: true });
          }
        });
    }
  };

  const validate = (e) => {
    let isValid = true;
    let userInput = val.input;

    let errors = {};
    if (userInput.uname == "") {
      errors["nameErr"] = "Please fill the name";
      isValid = false;
    }
    if (userInput.phone_no == "") {
      errors["phoneErr"] = "Please fill the phone number";
      isValid = false;
    }
    setValue((prev) => ({ ...prev, errors: errors }));
    return isValid;
  };
  return (
    <div className="edit-popup">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Edit Profile
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <div className="popup-field">
              <label>Name</label>
              <TextField
                type="text"
                name="uname"
                onChange={handleChange}
                placeholder="Enter Your Name"
                value={val.input.uname}
              />
              {val.errors.nameErr && (
                <span className="error-msg">{val.errors.nameErr}</span>
              )}
            </div>
            <div className="popup-field">
              <label>Email</label>
              <TextField
                type="text"
                placeholder={val.input.email}
                disabled={true}
              />
              <span></span>
              {/* <span className="error-msg">Please enter the required field</span> */}
            </div>
            <div className="popup-field">
              <label>Phone Number</label>
              <TextField
                type="text"
                name="phone_no"
                placeholder="Enter Your Phone Number Ex: +1"
                onChange={handleChange}
                value={val.input.phone_no}
              />
              {val.errors.phoneErr && (
                <span className="error-msg">{val.errors.phoneErr}</span>
              )}
            </div>
            <div className="popup-field">
              <label>Title</label>
              <TextField
                type="text"
                placeholder={val.input.name}
                disabled={true}
              />

              {/* <span className="error-msg">Please enter the required field</span> */}
            </div>

            {successMsg.msg ? (
              <span className={successMsg.status ? "error-msg" : "success-msg"}>
                {successMsg.msg}
              </span>
            ) : (
              ""
            )}
            <div className="dialog-btn-wrap">
              <DialogActions>
                <Button variant="contained" type="submit" disabled={loader}>
                  {loader && <CircularProgress size={24} />}
                  Done
                </Button>
              </DialogActions>
            </div>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default EditProfileDialog;
