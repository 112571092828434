// all public routes 


import { Redirect, Route } from 'react-router-dom';

function Public({component: Component,...rest}){
   let authorizations = localStorage.getItem('authStatus') == 1 ? 1 :0;
    return <Route {...rest} render={(props) =>(
         ( authorizations ) ? <Redirect to='/dashboard' /> : <Component {...props} />
    )} />
}

export default Public;
